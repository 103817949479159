<template>
<v-container>
  <v-card outlined>
    <v-card-title>
      <v-btn icon @click="goBack()">
        <v-icon>arrow_back</v-icon>
      </v-btn>
      日志查询
      <v-spacer></v-spacer>
      <v-text-field
        v-model="query.key"
        :background-color="$vuetify.theme.dark ? '' : 'grey lighten-4'"
        @keydown.enter="searchItem"
        label="关键字搜索（按回车）"
        prepend-inner-icon="search"
        solo
        flat
        dense
        hide-details
        clearable
      >
        <template v-slot:append>
          <v-menu v-model="query.menu" :close-on-content-click="false" :nudge-width="350" offset-y left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>tune</v-icon>
              </v-btn>
            </template>
            <v-card>
              <v-container>
                <v-row class="align-center">
                  <v-col cols="4" class="text-subtitle-2">操作对象</v-col>
                  <v-col cols="8">
                    <v-select
                    v-model="query.type"
                    :items="logsType"
                    item-text="name"
                    item-value="code"
                    dense
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row class="align-center">
                  <v-col cols="4" class="text-subtitle-2">操作时间</v-col>
                  <v-col cols="8"></v-col>
                </v-row>
              </v-container>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="query.menu = false">
                  搜索
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-menu>
        </template>
      </v-text-field>
    </v-card-title>
    <v-data-table
      v-model="selectedItem"
      :headers="headerTable"
      :items="logsList"
      :options.sync="optionsTable"
      :server-items-length="logsCount"
      :loading="logsLoading"
      @click:row="singleClick"
      :footer-props="{'items-per-page-options': [5, 10,15, 30, 50, 100]}"
      loading-text="数据读取中... 请稍后"
      item-key="_id"
      transition
      show-expand
    >
      <template v-slot:item.createTime="{ item }">
        <td nowrap="true">{{item.createTime | formatTime}}</td>
      </template>
      <template v-slot:item.objId="{ item }">
        <td nowrap="true" v-if="item.objType === 'Account'">{{item.objId.map(obj => obj.personal.name)}}</td>
        <td nowrap="true" v-else-if="item.objType === 'Contract'">{{item.objId.map(obj => obj.contract_name)}}</td>
        <td nowrap="true" v-else-if="item.objType === 'Notes'">{{item.objId.map(obj => obj.type)}}</td>
        <td nowrap="true" v-else-if="item.objType === 'CaseSettle'">{{item.objId.map(obj => obj.settleTitle)}}</td>
        <td nowrap="true" v-else-if="item.objType === 'Settle'">结算数据</td>
        <td nowrap="true" v-else-if="item.objType === 'SettleSumData'">结算单汇总</td>
        <td nowrap="true" v-else>{{item.objId.map(obj => obj.name)}}</td>
      </template>
      <template v-slot:item.operator="{ item }">
        <td nowrap="true">{{item.operator.personal.name}}</td>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          {{ item.record }}
        </td>
      </template>
    </v-data-table>
  </v-card>
  <v-navigation-drawer v-model="selectDrawer" app right>
    <v-container>
      <v-toolbar-title>您共选择了{{selectedItem.length}}个项目</v-toolbar-title>
      <v-card outlined class="ma-2" v-for="item in selectedItem" :key="item._id">
        <v-card-title>
          {{item.objType}}
          <v-spacer></v-spacer>
          <v-btn icon small><v-icon>remove</v-icon></v-btn>
        </v-card-title>
        <v-card-text>
          <v-list dense>
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title>操作人</v-list-item-title>
                <v-list-item-subtitle>{{item.operator.personal.name}}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title>操作时间</v-list-item-title>
                <v-list-item-subtitle>{{item.createTime | formatTime}}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>

        </v-card-text>
      </v-card>
    </v-container>
  </v-navigation-drawer>
</v-container>
</template>
<script>
import store from "@/store";
import {FETCH_LOGS_LIST} from "@/store/actions.type";
import {mapGetters} from "vuex";
import Util from "@/common/util";

export default {
  data() {
    return {
      query: {
        menu: false,
        key: '',
      },
      defaultQuery: {
        menu: false,
        key: '',
      },
      optionsTable: {},
      headerTable: [
        {text: '操作日期', width: '125px', value: 'createTime'},
        {text: '操作对象', width: '125px', value: 'objType'},
        {text: '对象名称', width: '125px', value: 'objId'},
        {text: '执行动作', width: '125px', value: 'action'},
        {text: '操作人', width: '125px', value: 'operator'},
        { text: '', value: 'data-table-expand' },
      ],
      selectDrawer: false,
      clickTimer: null,
      selectedItem: [],
      rightMenu: false,
      mouseX: 0,
      mouseY: 0
    }
  },
  created() {

  },
  computed: {
    ...mapGetters([
      "currentUser",
      "logsList",
      "logsLoading",
      "logsCount",
    ]),
    logsType () {
      return Util.categories('logsType')
    },
  },
  watch: {
    optionsTable: {
      handler() {
        this.getDataList(this.query)
      },
      deep: true,
    },
  },
  methods: {
    goBack() {
      this.$router.push({path: '/distributor/system'});
    },
    searchItem() {
      this.optionsTable.page = 1
      this.getDataList({ key: this.query.key })
    },
    getDataList() {
      const {sortBy, sortDesc, page, itemsPerPage} = this.optionsTable;
      let sort = '';
      if (sortBy && sortBy.length === 1) {
        if (sortDesc[0] === true) {
          sort = '-' + sortBy[0];
        } else {
          sort = sortBy[0];
        }
      }
      if (Object.keys(this.$route.query).length > 0) {
        this.query = Object.assign(this.query, this.$route.query);
      }
      let urlQuery = '';
      if (this.query.key) urlQuery += '&key=' + this.query.key

      if (Object.keys(this.optionsTable).length > 0) {
        store.dispatch(FETCH_LOGS_LIST, 'page=' + page + '&limit=' + itemsPerPage + '&sort=' + sort + urlQuery)
      }
    },
    singleClick(v) {
      if (this.clickTimer === null) {
        this.clickTimer = setTimeout(() => {
          this.clickTimer = null
          let item = Object.assign({}, v)
          let index = this.selectedItem.findIndex((t) => t._id === item._id)
          if (index > -1) {
            this.selectedItem.splice(index, 1)
          } else {
            this.selectedItem.push(item)
          }
          this.selectDrawer = true
        }, 300)
      } else {
        clearTimeout(this.clickTimer)
        this.clickTimer = null
      }
    }
  }
}
</script>
<style scoped>
</style>
